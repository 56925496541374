"use strict";

import axios from "axios";
import TrustdRequest from "@/model/TrustdRequest";
import TrustdResponse from "@/model/TrustdResponse";
import TrustdInvitation from "@/model/TrustdInvitation";
import TrustdUser from "@/model/TrustdUser";
import AuthService from "@/service/AmplifyAuth";
import TrustdPayload from "@/model/TrustdPayload";
import GuestFormRequest from "@/model/GuestFormRequest";

const OWNERREZ_API_ADDRESS = "/tpc/ownerrez/api";
const HOSTAWAY_API_ADDRESS = "/tpc/hostaway/api";
const HOSTAWAY_API_SIGNUP = "/signup";
const OWNERREZ_API_SIGNUP = "/signup";
//const OWNERREZ_API_GET_USER = '/get-user'
const TRUSTD_API_ADDRESS = window.location.origin;
const TRUSTD_API_VERSION = "/v1";
const TRUSTD_API_MATCH = "/match";
const TRUSTD_API_INVITATION = "/client/invitation";
const TRUSTD_API_USERS = "/users";
const TRUSTD_API_MATCH_BY_HOST_EMAIL = "/match/byhostemail";
const TRUSTD_API_MATCH_BY_HOST_EMAIL_FILTERED = "/match/byhostemail/filtered";
const TRUSTD_API_MATCH_BY_HOST_EMAIL_AND_REFERENCE =
  "/match/byhostemailandreference";
const TRUSTD_API_MATCH_DASHBOARD_BY_HOST_EMAIL = "/match/dashboard";
const TRUSTD_API_GUEST_FORM = "/guestForm";
const TRUSTD_API_GUEST_FORM_BY_ID = "/guestForm/id";
const TRUSTD_API_GUEST_FORM_BY_MATCH_REQUEST_ID = "/guestForm/matchRequestId";
const TRUSTD_API_GUEST_FORM_BY_HOST_EMAIL = "/guestForm/byHostEmail";
const TRUSTD_API_GUEST_FORM_SEND_EMAIL = "/guestForm/sendGuestFormEmail";

export default class TrustdService {
  static async callMatchApi(guest, host) {
    const trustdPayload = TrustdRequest.create(guest, host);

    const headers = {
      "Content-Type": "application/json",
      "x-api-key": "Sh7yxT0sFO1aujXJhKfo537kU0RgYkwTNdybZWH2", // todo
    };

    const apiUrl = TRUSTD_API_ADDRESS + TRUSTD_API_VERSION + TRUSTD_API_MATCH;
    console.log("apiUrl: ", apiUrl);

    const response = await axios.post(apiUrl, trustdPayload, {
      headers: headers,
    });
    return TrustdResponse.create(response.data);
  }

  static async refreshMatchApiResultsResponse(trustdReference) {
    const headers = {
      "Content-Type": "application/json",
      "x-api-key": "Sh7yxT0sFO1aujXJhKfo537kU0RgYkwTNdybZWH2",
    };

    const apiUrl =
      TRUSTD_API_ADDRESS +
      TRUSTD_API_VERSION +
      TRUSTD_API_MATCH +
      "/" +
      trustdReference;
    console.log("apiUrl: ", apiUrl);

    const response = await axios.get(apiUrl, { headers: headers });
    return TrustdResponse.create(response.data);
  }

  static async verifyInvitationCode(invitationCode) {
    const headers = {
      "Content-Type": "application/json",
      "x-api-key": "Sh7yxT0sFO1aujXJhKfo537kU0RgYkwTNdybZWH2",
    };

    const apiUrl =
      TRUSTD_API_ADDRESS +
      TRUSTD_API_VERSION +
      TRUSTD_API_INVITATION +
      "/" +
      invitationCode;
    console.log("apiUrl: ", apiUrl);

    const response = await axios.get(apiUrl, { headers: headers });
    return TrustdInvitation.create(response.data);
  }

  static async saveUserInfo(userForm) {
    const userPayload = TrustdUser.createFromUserForm(userForm);
    const hostawayPayload = {
      clientId: userForm.accountId,
      apiKey: userForm.apiKey,
      email: userForm.username,
      name: userForm.contactName,
      company: userForm.company,
      phone: userForm.phoneNumber,
      screening: userForm.screening,
      freeScreening: userForm.freeScreening,
      status: userForm.status,
      terms: "on",
      sendEmail: "YES",
    };

    console.log("userPayload", userPayload);

    const headers = {
      "Content-Type": "application/json",
      "x-api-key": "Sh7yxT0sFO1aujXJhKfo537kU0RgYkwTNdybZWH2",
    };

    const apiUrl = TRUSTD_API_ADDRESS + TRUSTD_API_VERSION + TRUSTD_API_USERS;
    const hostawayApiUrl = HOSTAWAY_API_ADDRESS + HOSTAWAY_API_SIGNUP;
    console.log("apiUrl: ", apiUrl);

    await axios.post(apiUrl, userPayload, { headers: headers });

    if (
      userForm.channel == "hostaway" &&
      userForm.accountId != "" &&
      userForm.apiKey != ""
    ) {
      await axios.post(hostawayApiUrl, hostawayPayload, { headers: headers });
    }
    // do not return
  }

  static async getScoreByHostEmailAndReference(hostEmail, trustdReference) {
    console.log(`hostEmail ${hostEmail} and reference ${trustdReference}`);

    const headers = {
      "Content-Type": "application/json",
      "x-api-key": "Sh7yxT0sFO1aujXJhKfo537kU0RgYkwTNdybZWH2",
    };

    const apiUrl =
      TRUSTD_API_ADDRESS +
      TRUSTD_API_VERSION +
      TRUSTD_API_MATCH_BY_HOST_EMAIL_AND_REFERENCE +
      "/" +
      hostEmail.toLowerCase() +
      "/" +
      trustdReference;
    console.log("apiUrl: ", apiUrl);

    const response = await axios.get(apiUrl, { headers: headers });
    console.log("response: ", response);
    return {
      payload: TrustdPayload.create(response.data.Payload),
      response: TrustdResponse.create(response.data.Response),
    };
  }

  static async listReviewsByHostEmail() {
    const user = await AuthService.currentAuthenticatedUserAttributes();
    console.log("hostEmail", user.username);

    const headers = {
      "Content-Type": "application/json",
      "x-api-key": "Sh7yxT0sFO1aujXJhKfo537kU0RgYkwTNdybZWH2",
    };

    const apiUrl =
      TRUSTD_API_ADDRESS +
      TRUSTD_API_VERSION +
      TRUSTD_API_MATCH_BY_HOST_EMAIL +
      "/" +
      user.username.toLowerCase();
    console.log("apiUrl: ", apiUrl);

    const response = await axios.get(apiUrl, { headers: headers });
    console.log("response.data: ", response.data);
    return response.data;
  }

  static async listReviewsByHostEmailFiltered(page, pageSize, filters) {
    const user = await AuthService.currentAuthenticatedUserAttributes();
    console.log("hostEmail", user.username);

    const headers = {
      "Content-Type": "application/json",
      "x-api-key": "Sh7yxT0sFO1aujXJhKfo537kU0RgYkwTNdybZWH2",
    };

    const body = {
      page: page,
      pageSize: pageSize,
      filters: filters || [],
    };

    const apiUrl =
      TRUSTD_API_ADDRESS +
      TRUSTD_API_VERSION +
      TRUSTD_API_MATCH_BY_HOST_EMAIL_FILTERED +
      "/" +
      user.username.toLowerCase();
    console.log("apiUrl: ", apiUrl);

    const response = await axios
      .post(apiUrl, body, { headers: headers })
      .catch((error) => {
        console.log("list reviews by hostEmail filtered error:", error);
        return {
          data: {
            Data: [],
            TotalDocuments: 0,
            CurrentPage: 1,
            CurrentPageSize: 0,
            TotalPages: 1,
          },
        };
      });
    console.log("response.data: ", response.data);
    return response.data;
  }

  static async saveGuestFormRequest(guest, booking, status, matchRequestId) {
    const user = await AuthService.currentAuthenticatedUserAttributes();
    console.log("hostEmail", user.username);

    const rawData = {
      hostEmail: user.username.toLowerCase(),
      status: status,
    };
    if (arguments.length > 3) {
      rawData.matchRequestId = matchRequestId;
    }
    const guestFormPayload = GuestFormRequest.create(rawData, guest, booking);
    console.log("guestFormPayload", guestFormPayload);

    const headers = {
      "Content-Type": "application/json",
      "x-api-key": "Sh7yxT0sFO1aujXJhKfo537kU0RgYkwTNdybZWH2",
    };

    const apiUrl =
      TRUSTD_API_ADDRESS + TRUSTD_API_VERSION + TRUSTD_API_GUEST_FORM;
    console.log("apiUrl: ", apiUrl);

    const response = await axios.post(apiUrl, guestFormPayload, {
      headers: headers,
    });
    return response.data.Id;
  }

  static async updateGuestFormRequest(
    id,
    guest,
    booking,
    status,
    matchRequestId,
    hostEmail
  ) {
    if (id == null || id === "") {
      console.log("id required");
      return;
    }

    const rawData = {
      hostEmail: hostEmail.toLowerCase(),
      status: status,
    };
    if (arguments.length > 3) {
      rawData.matchRequestId = matchRequestId;
    }
    const guestFormPayload = GuestFormRequest.create(rawData, guest, booking);
    console.log("guestFormPayload", guestFormPayload);

    const headers = {
      "Content-Type": "application/json",
      "x-api-key": "Sh7yxT0sFO1aujXJhKfo537kU0RgYkwTNdybZWH2",
    };

    const apiUrl =
      TRUSTD_API_ADDRESS +
      TRUSTD_API_VERSION +
      TRUSTD_API_GUEST_FORM +
      "/" +
      id;
    console.log("apiUrl: ", apiUrl);

    const response = await axios.post(apiUrl, guestFormPayload, {
      headers: headers,
    });
    return response.data.Id;
  }

  static async updateGuestFormRequestGuest(id, guest) {
    if (id == null || id === "") {
      console.log("id required");
      return;
    }

    const headers = {
      "Content-Type": "application/json",
      "x-api-key": "Sh7yxT0sFO1aujXJhKfo537kU0RgYkwTNdybZWH2",
    };

    const payload = {};
    payload.GuestInfo = {
      email: guest.email,
      phone: guest.phone,
      first_name: guest.firstName,
      middle_name: guest.middleName,
      last_name: guest.lastName,
      dob: guest.birthDate,
    };
    console.log("payload", payload);

    const apiUrl =
      TRUSTD_API_ADDRESS +
      TRUSTD_API_VERSION +
      TRUSTD_API_GUEST_FORM +
      "/" +
      id;
    console.log("apiUrl: ", apiUrl);

    const response = await axios.post(apiUrl, payload, { headers: headers });
    return response.data.Id;
  }

  static async getGuestFormRequestById(id) {
    const headers = {
      "Content-Type": "application/json",
      "x-api-key": "Sh7yxT0sFO1aujXJhKfo537kU0RgYkwTNdybZWH2",
    };

    const apiUrl =
      TRUSTD_API_ADDRESS +
      TRUSTD_API_VERSION +
      TRUSTD_API_GUEST_FORM_BY_ID +
      "/" +
      id;
    console.log("apiUrl: ", apiUrl);

    const response = await axios
      .get(apiUrl, { headers: headers })
      .catch((error) => {
        console.log("Error retrieving guest form request: " + error);
        return {
          guestFormRequest: null,
        };
      });
    console.log("response.data: ", response.data);

    if (response.status !== 200 || response.data.length === 0) {
      return {
        guestFormRequest: null,
      };
    }

    return {
      guestFormRequest: response.data,
    };
  }

  static async getGuestFormRequestByMatchRequestId(matchRequestId) {
    const headers = {
      "Content-Type": "application/json",
      "x-api-key": "Sh7yxT0sFO1aujXJhKfo537kU0RgYkwTNdybZWH2",
    };

    const apiUrl =
      TRUSTD_API_ADDRESS +
      TRUSTD_API_VERSION +
      TRUSTD_API_GUEST_FORM_BY_MATCH_REQUEST_ID +
      "/" +
      matchRequestId;
    console.log("apiUrl: ", apiUrl);

    const response = await axios.get(apiUrl, { headers: headers });
    console.log("response.data: ", response.data);

    const rawData = {
      matchRequestId: response.data.MatchRequestId,
      hostEmail: response.data.HostEmail,
      status: response.data.Status,
    };

    return {
      guestFormRequest: GuestFormRequest.create(
        rawData,
        response.data.GuestInfo,
        response.data.BookingInfo
      ),
    };
  }

  static async listGuestFormRequestByHostEmail() {
    const user = await AuthService.currentAuthenticatedUserAttributes();
    console.log("hostEmail", user.username);

    const headers = {
      "Content-Type": "application/json",
      "x-api-key": "Sh7yxT0sFO1aujXJhKfo537kU0RgYkwTNdybZWH2",
    };

    const apiUrl =
      TRUSTD_API_ADDRESS +
      TRUSTD_API_VERSION +
      TRUSTD_API_GUEST_FORM_BY_HOST_EMAIL +
      "/" +
      user.username.toLowerCase();
    console.log("apiUrl: ", apiUrl);

    const response = await axios
      .get(apiUrl, { headers: headers })
      .catch((error) => {
        console.log("guestFormRequest by hostEmail error:", error);
        return [];
      });
    console.log("response.data: ", response.data);
    return response.data;
  }

  static async sendGuestFormEmail(guestFormId, guestFormUrl) {
    const payload = {
      guestFormUrl: guestFormUrl,
    };

    const headers = {
      "Content-Type": "application/json",
      "x-api-key": "Sh7yxT0sFO1aujXJhKfo537kU0RgYkwTNdybZWH2",
    };

    const apiUrl =
      TRUSTD_API_ADDRESS +
      TRUSTD_API_VERSION +
      TRUSTD_API_GUEST_FORM_SEND_EMAIL +
      "/" +
      guestFormId;
    console.log("apiUrl: ", apiUrl);

    const response = await axios
      .post(apiUrl, payload, { headers: headers })
      .catch((error) => {
        return {
          statusCode: error.response.status,
          errorData: error.response.data,
        };
      });

    return response.data.Id;
  }

  static async getDashboardInformationByHostEmail() {
    const user = await AuthService.currentAuthenticatedUserAttributes();
    console.log("hostEmail", user.username);

    const headers = {
      "Content-Type": "application/json",
      "x-api-key": "Sh7yxT0sFO1aujXJhKfo537kU0RgYkwTNdybZWH2",
    };

    const apiUrl =
      TRUSTD_API_ADDRESS +
      TRUSTD_API_VERSION +
      TRUSTD_API_MATCH_DASHBOARD_BY_HOST_EMAIL +
      "/" +
      user.username.toLowerCase();
    console.log("apiUrl: ", apiUrl);

    const response = await axios
      .get(apiUrl, { headers: headers })
      .catch((error) => {
        console.log("dashboardInformaiton by hostEmail error:", error);
        return [];
      });
    console.log("response.data: ", response.data);

    return response.data;
  }

  static async getUserInformationByHostEmail() {
    const user = await AuthService.currentAuthenticatedUserAttributes();
    console.log("hostEmail", user.username);

    const apiUrl =
      TRUSTD_API_ADDRESS +
      TRUSTD_API_VERSION +
      TRUSTD_API_USERS +
      "/" +
      user.username.toLowerCase();
    console.log("apiUrl: ", apiUrl);

    const headers = {
      "Content-Type": "application/json",
      "x-api-key": "Sh7yxT0sFO1aujXJhKfo537kU0RgYkwTNdybZWH2",
    };

    const response = await axios.get(
      "/stripe/users/" + user.username.toLowerCase(),
      { headers }
    );

    console.log("user info: ", response.data);

    return response.data;
  }

  static async ownerrezTempUserCreate(form) {
    const headers = {
      "Content-Type": "application/json",
      "x-api-key": "Sh7yxT0sFO1aujXJhKfo537kU0RgYkwTNdybZWH2",
    };

    const apiUrl = OWNERREZ_API_ADDRESS + OWNERREZ_API_SIGNUP;

    console.log(apiUrl, form);

    return await axios.post(apiUrl, form, { headers });
  }

  static async saveTagIfOwnerrez(email, matchApiResponse, guest, booking) {
    try {
      const headers = {
        "Content-Type": "application/json",
        "x-api-key": "Sh7yxT0sFO1aujXJhKfo537kU0RgYkwTNdybZWH2",
      };

      const apiUrl =
        TRUSTD_API_ADDRESS +
        TRUSTD_API_VERSION +
        TRUSTD_API_USERS +
        "/" +
        email.toLowerCase();
      console.log("apiUrl: ", apiUrl);

      const response = await axios.get("/stripe/users/" + email.toLowerCase(), {
        headers,
      });

      console.log("user info: ", response.data);

      if (response.data.channel == "ownerrez") {
        const payload = {
          email,
          score:
            matchApiResponse.arrestList == "CLEAR" &&
            matchApiResponse.criminalList == "CLEAR" &&
            matchApiResponse.sexOffenderList == "CLEAR" &&
            matchApiResponse.wantedList == "CLEAR" &&
            matchApiResponse.watchList == "CLEAR"
              ? "CLEAR"
              : "NAME MATCH",
          trustdReference: matchApiResponse.trustdReference,
          guest,
          booking,
        };

        console.log("saveTagIfOwnerrez payload: ", payload);

        const response2 = await axios.post("/tpc/ownerrez/tag", payload, {
          headers,
          timeout: 0,
        });

        console.log(response2.data);
      }
    } catch (error) {
      console.log(error.response.data);
    }
  }

  static async saveTagIfHostaway(email, matchApiResponse, guest, booking) {
    try {
      const headers = {
        "Content-Type": "application/json",
        "x-api-key": "Sh7yxT0sFO1aujXJhKfo537kU0RgYkwTNdybZWH2",
      };

      const apiUrl =
        TRUSTD_API_ADDRESS +
        TRUSTD_API_VERSION +
        TRUSTD_API_USERS +
        "/" +
        email.toLowerCase();
      console.log("apiUrl: ", apiUrl);

      const response = await axios.get("/stripe/users/" + email.toLowerCase(), {
        headers,
      });

      console.log("user info: ", response.data);

      if (response.data.channel == "hostaway") {
        const payload = {
          email,
          score:
            matchApiResponse.arrestList == "CLEAR" &&
            matchApiResponse.criminalList == "CLEAR" &&
            matchApiResponse.sexOffenderList == "CLEAR" &&
            matchApiResponse.wantedList == "CLEAR" &&
            matchApiResponse.watchList == "CLEAR"
              ? "CLEAR"
              : "NAME MATCH",
          trustdReference: matchApiResponse.trustdReference,
          guest,
          booking,
        };

        console.log("saveTagIfHostaway payload: ", payload);

        const response2 = await axios.post("/tpc/hostaway/tag", payload, {
          headers,
          timeout: 0,
        });

        console.log(response2.data);
      }
    } catch (error) {
      console.log(error.response.data);
    }
  }
}
